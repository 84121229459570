import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { Error } from '../../components/Auth/AuthStyles';
import { useLocale } from '../../contexts/LocalizationContext';
import { useNewsletter } from '../../contexts/NewsletterContext';
import { validateEmail } from '../../helper/helper';
import { saveEventV3 } from '../../utils/eventTracking';
import LoaderCircle from '../LoaderDots/LoaderCircle';
import Button from '../NewButton/Button';

import { useTheme } from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';
import { useESim } from '../../contexts/ESimContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { InputElement } from './NewsletterPdpPopUpStyles';
import {
  InputContainer,
  NewsletterContainer,
  NewsletterInputContainer,
  Title1,
  Title2,
} from './NewsletterStyles';
interface NewsLetterProps {}

export const Newsletter = (props: NewsLetterProps) => {
  const router = useRouter();
  const { isESim } = useESim();
  const { width } = useWindowDimensions();
  const theme = useTheme();

  const { messages } = useLocale();
  const {
    get_personalized_game_deals_msg,
    you_can_unsubscribe_at_any_msg,
    visit_for_more_information_msg,
    privacy_policy_msg,
    enter_your_email_address_msg,
    subscribe_msg,
    enter_a_valid_email_msg,
  } = messages || {};

  const [email, setEmail] = useState<string>('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError('');
  };
  const { handleNewsletterCLick } = useNewsletter();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const onSubmit = useCallback(
    async (action: 'subscribe' | 'unsubscribe') => {
      if (!email || !validateEmail(email)) {
        setError(enter_a_valid_email_msg || 'Please enter a valid email');
        return;
      }

      setLoading(true);

      const response = await handleNewsletterCLick(email, action);

      if (response) {
        setLoading(false);
        setEmail('');
      }

      setLoading(false);
    },
    [handleNewsletterCLick, email, enter_a_valid_email_msg]
  );

  return (
    <NewsletterContainer>
      <Title1
        style={
          isESim
            ? {
                fontSize: '16px',
                color: theme.palette.text.t2,
                marginBottom: '12px',
              }
            : {}
        }
      >
        {get_personalized_game_deals_msg}
      </Title1>
      <NewsletterInputContainer>
        <InputContainer
          style={
            isESim
              ? {
                  height: '40px',
                  width:
                    width &&
                    width > getNumFromResolutionConstant(WIDTH.mobileMax)
                      ? '256px'
                      : 'auto',
                }
              : {}
          }
        >
          <InputElement
            type="email"
            value={email}
            style={
              isESim ? { height: '40px', width: '100%' } : { width: '100%' }
            }
            onChange={handleInputChange}
            placeholder={enter_your_email_address_msg}
          />
          {!!error && <Error>{error}</Error>}
        </InputContainer>
        {isESim ? (
          <Button
            buttonTheme="primary"
            disabled={loading}
            onClick={() => {
              onSubmit('subscribe');
              saveEventV3({
                category: 'newsletter',
                action: 'click',
                label: 'newsletter-subscribe-btn',
                properties: 'click',
                value: ['footer'],
                from: router,
              });
            }}
            width="120px"
            height="40px"
            style={{ borderRadius: '24px', fontSize: '14px' }}
          >
            {loading ? <LoaderCircle /> : subscribe_msg}
          </Button>
        ) : (
          <Button
            buttonTheme="grey"
            disabled={loading}
            onClick={() => {
              onSubmit('subscribe');
              saveEventV3({
                category: 'newsletter',
                action: 'click',
                label: 'newsletter-subscribe-btn',
                properties: 'click',
                value: ['footer'],
                from: router,
              });
            }}
            width="160px"
            height="48px"
          >
            {loading ? <LoaderCircle /> : subscribe_msg}
          </Button>
        )}
      </NewsletterInputContainer>
      <Title2>
        {you_can_unsubscribe_at_any_msg}{' '}
        {visit_for_more_information_msg?.split('[--PLACEHOLDER--]')[0]}
        <Link
          href={isESim ? '/esim/privacy-policy' : '/privacy-policy'}
          passHref
          prefetch={false}
        >
          <span>&nbsp;{privacy_policy_msg}&nbsp;</span>
        </Link>
        {visit_for_more_information_msg
          ?.split('[--PLACEHOLDER--]')[1]
          ?.concat('.')}
      </Title2>
    </NewsletterContainer>
  );
};
