import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const AuthContainer = styled.div`
  min-height: calc(100vh - 159px);
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

export const AuthTitle = styled.p`
  font-size: 24px;
  line-height: 24px;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  margin-bottom: 32px;
`;

export const AuthSubtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-family: Onest-Medium;
  color: ${COLORS.gray3};
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  // margin-bottom: 24px;
`;

export const InputLabel = styled.label`
  font-size: 16px;
  line-height: 24px;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const Input = styled.input`
  background: ${(props) => props.theme.palette.background.bg3};
  outline: none;
  border-radius: 8px;
  padding: 13px 19px;
  color: ${(props) => props.theme.palette.text.t1};
  font-size: 16px;
  line-height: 24px;
  font-family: Onest-Medium;
  &::placeholder {
    color: ${(props) => props.theme.palette.text.t3};
  }
`;

export const LightButton = styled.button`
  background: ${(props) => props.theme.palette.background.bg3};
  color: ${(props) => props.theme.palette.text.t1};
  font-family: Onest-Bold;
  line-height: 14px;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 16px;
  width: fit-content;
`;

export const ChangePageController = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: Onest-Medium;
  display: flex;
  align-items: center;
  margin-top: 16px;
  p {
    margin-right: 12px;
  }
  color: ${(props) => props.theme.palette.text.t1};
`;

export const InputsContainer = styled.div`
  // flex-grow: 1;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Error = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.palette.error.main};
`;
