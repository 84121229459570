import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const NewsletterPopUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.background.bg1};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px 24px 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider.d1};
  & > div {
    position: absolute
    align-items: start;
    
  }
`;
export const Title1 = styled.p`
  font: 1.25rem/1.5rem Onest-SemiBold;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  gap: 8px;
`;
export const Title2 = styled.p`
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t2};
`;

export const Title3 = styled.p`
  font: 14px Onest-Medium;
  display: flex;
  flex-wrap: wrap;
  color: ${(props) => props.theme.palette.text.t2};
  span {
    color: ${(props) => props.theme.palette.text.primary};
    cursor: pointer;
  }
`;

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  display: grid;
  place-content: center;
  &:hover {
    background: ${(props) => props.theme.palette.background.bg2};
  }
`;

export const InputElement = styled.input`
  background: #fff;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  font-family: Onest-Medium;
  outline: none;
  width: 100%;
  color: #000;
  @media (max-width: ${WIDTH.mobileMax}) {
    max-width: ${WIDTH.mobileMax};
    width: 100%;
  }
`;
