import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const NewsletterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: ${WIDTH.mobileMid}) {
    width: 100%;
  }
`;

export const NewsletterInputContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  // @media (max-width: ${WIDTH.mobileMid}) {
  //   flex-direction: column;
  // }
`;

export const Title1 = styled.p`
  font: 1rem/1.25rem Onest-Medium;
  color: #fff;
`;

export const Title2 = styled.p`
  font-family: Onest-Medium;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  color: ${(props) => props.theme.palette.text.t3};
  span {
    color: ${(props) => props.theme.palette.text.primary};
    cursor: pointer;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 368px;
  @media (max-width: ${WIDTH.mobileMid}) {
    width: 330px;
  }
`;
