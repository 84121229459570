import { useEffect, useRef } from 'react';

function useTrustpilotRef() {
  const ref = useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if ((window as any)?.Trustpilot) {
      (window as any)?.Trustpilot?.loadFromElement(ref.current, true);
    }
  }, []);

  return {
    ref,
  };
}

export default useTrustpilotRef;
