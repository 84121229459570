import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useLocale } from '../../contexts/LocalizationContext';
import { AppThemeContext } from '../../contexts/ThemeContext';
import { saveEventV3 } from '../../utils/eventTracking';
import { WE_USER_EVENTS_FOOTER_LINK_CLICKED } from '../../utils/we';
import { MaskIcon } from '../Icons/Icons';
import {
  BrandLogoImage,
  CompanyInfo,
  FooterPart,
  FooterStrip,
  FooterStripContainer,
  FooterStripNavContainer,
  IconContainer,
  IconsContainer,
  RecaptchaBadgeContainer,
} from './FooterStyle';

const FooterBottomStrip = () => {
  const router = useRouter();
  const theme = useContext(ThemeContext);
  const appTheme = useContext(AppThemeContext);
  const { cleverTap } = useAnalytics();

  const { messages } = useLocale();
  const { terms_of_service_msg, privacy_policy_msg, refund_policy_msg } =
    messages || {};

  return (
    <FooterStripContainer>
      <FooterStrip>
        <FooterPart>
          <Link href="/" passHref>
            <a>
              <BrandLogoImage
                src={`/logo/${'driffle-logo-white'}.svg`}
                // src={`/logo/${
                //   appTheme?.theme === 'dark'
                //     ? 'driffle-logo-white'
                //     : 'driffle-logo-black'
                // }.svg`}
                alt="Driffle logo"
              />
            </a>
          </Link>
          <FooterStripNavContainer>
            <Link href="/terms-and-conditions" passHref prefetch={false}>
              <a
                onClick={() => {
                  saveEventV3({
                    category: 'footer',
                    action: 'click',
                    label: 'terms-and-conditions',
                    properties: '',
                    value: ['/terms-and-conditions'],
                    from: router,
                  });
                  WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                    {
                      footerOptionName: 'Terms of service',
                      link: '/terms-and-conditions',
                      pageUrl: window.location.href,
                    },
                    cleverTap
                  );
                }}
              >
                {terms_of_service_msg}
              </a>
            </Link>
            <Link href="/privacy-policy" passHref prefetch={false}>
              <a
                onClick={() => {
                  saveEventV3({
                    category: 'footer',
                    action: 'click',
                    label: 'privacy-policy',
                    properties: '',
                    value: ['/privacy-policy'],
                    from: router,
                  });
                  WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                    {
                      footerOptionName: 'Privacy policy',
                      link: '/privacy-policy',
                      pageUrl: window.location.href,
                    },
                    cleverTap
                  );
                }}
              >
                {privacy_policy_msg}
              </a>
            </Link>
            <Link href="/refund-policy" passHref prefetch={false}>
              <a
                onClick={() => {
                  saveEventV3({
                    category: 'footer',
                    action: 'click',
                    label: 'refund-policy',
                    properties: '',
                    value: ['/refund-policy'],
                    from: router,
                  });
                  WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                    {
                      footerOptionName: 'Refund policy',
                      link: '/refund-policy',
                      pageUrl: window.location.href,
                    },
                    cleverTap
                  );
                }}
              >
                {refund_policy_msg}
              </a>
            </Link>

            <a
              href="#"
              className="termly-display-preferences"
              // onClick={() => {
              //   saveEventV3({
              //     category: 'footer',
              //     action: 'click',
              //     label: 'cookie-preferences',
              //     properties: '',
              //     value: ['termly-display-preferences'],
              //     from: router,
              //   });
              //   WE_USER_EVENTS_FOOTER_LINK_CLICKED(
              //     {
              //       footerOptionName: 'Consent Preferences',
              //       link: 'termly-display-preferences',
              //       pageUrl: window.location.href,
              //     },
              //     cleverTap
              //   );
              // }}
            >
              Consent Preferences
            </a>
          </FooterStripNavContainer>
        </FooterPart>
        <IconsContainer>
          <IconContainer
            onClick={() => {
              saveEventV3({
                category: 'footer',
                action: 'click',
                label: 'Facebook social icon',
                properties: '',
                value: ['https://www.facebook.com/drifflecom/'],
                from: router,
              });
              WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                {
                  footerOptionName: 'Facebook social icon',
                  link: 'https://www.facebook.com/drifflecom/',
                  pageUrl: window.location.href,
                },
                cleverTap
              );
            }}
          >
            <a
              href="https://www.facebook.com/drifflecom/"
              aria-label="Facebook page"
            >
              <MaskIcon
                url="/icons/facebook-purple-icon.svg"
                selected={true}
                height="20px"
                // color={theme.palette.text.t1}
                color={'#fff'}
              />
            </a>
          </IconContainer>

          <IconContainer
            onClick={() => {
              saveEventV3({
                category: 'footer',
                action: 'click',
                label: 'Instagram social icon',
                properties: '',
                value: ['https://www.instagram.com/drifflecom/?hl=en'],
                from: router,
              });
              WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                {
                  footerOptionName: 'Instagram social icon',
                  link: 'https://www.instagram.com/drifflecom/?hl=en',
                  pageUrl: window.location.href,
                },
                cleverTap
              );
            }}
          >
            <a
              href="https://www.instagram.com/drifflecom/?hl=en"
              aria-label="Instagram page"
            >
              <MaskIcon
                url="/icons/instagram-purple.svg"
                selected={true}
                height="20px"
                // color={theme.palette.text.t1}
                color={'#fff'}
              />
            </a>
          </IconContainer>
          <IconContainer
            onClick={() => {
              saveEventV3({
                category: 'footer',
                action: 'click',
                label: 'Twitter social icon',
                properties: '',
                value: ['https://twitter.com/drifflecom'],
                from: router,
              });
              WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                {
                  footerOptionName: 'Twitter social icon',
                  link: 'https://twitter.com/drifflecom',
                  pageUrl: window.location.href,
                },
                cleverTap
              );
            }}
          >
            <a href="https://twitter.com/drifflecom" aria-label="Twitter page">
              <MaskIcon
                url="/icons/x-twitter.svg"
                selected={true}
                height="20px"
                // color={theme.palette.text.t1}
                color={'#fff'}
              />
            </a>
          </IconContainer>

          <IconContainer
            onClick={() => {
              saveEventV3({
                category: 'footer',
                action: 'click',
                label: 'Youtube social icon',
                properties: '',
                value: [
                  'https://www.youtube.com/channel/UCF2wJ30zZ3RcsqXOYCQMItg',
                ],
                from: router,
              });
              WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                {
                  footerOptionName: 'Youtube social icon',
                  link: 'https://www.youtube.com/channel/UCF2wJ30zZ3RcsqXOYCQMItg',
                  pageUrl: window.location.href,
                },
                cleverTap
              );
            }}
          >
            <a
              href="https://www.youtube.com/channel/UCF2wJ30zZ3RcsqXOYCQMItg"
              aria-label="Youtube page"
            >
              <MaskIcon
                url="/icons/youtube-purple.svg"
                selected={true}
                height="20px"
                // color={theme.palette.text.t1}
                color={'#fff'}
              />
            </a>
          </IconContainer>
          <IconContainer
            onClick={() => {
              saveEventV3({
                category: 'footer',
                action: 'click',
                label: 'Discord social icon',
                properties: '',
                value: ['https://discord.gg/MryXrnwZvz'],
                from: router,
              });
              WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                {
                  footerOptionName: 'Discord social icon',
                  link: 'https://discord.gg/MryXrnwZvz',
                  pageUrl: window.location.href,
                },
                cleverTap
              );
            }}
          >
            <a href="https://discord.gg/MryXrnwZvz" aria-label="Discord">
              <MaskIcon
                url="/icons/discord-icon-new.svg"
                selected={true}
                height="20px"
                // color={theme.palette.text.t1}
                color={'#fff'}
              />
            </a>
          </IconContainer>
        </IconsContainer>
      </FooterStrip>
      {/* <CompanyInfo
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(the_website_owned_msg ?? ''),
          }}
        /> */}
      <CompanyInfo data-nosnippet>
        {/* The website <span>driffle.com</span> is owned and operated by{' '}
          <span>Bluecut Limited </span>- 74A High Street, Wanstead, London E11
          2RJ, United Kingdom & <span>UAB Driffle</span>, Vilnius, Naugarduko g.
          3-401, LT-03231. */}
        The website driffle.com is owned and operated by{' '}
        {/* Bluecut Limited - 74A
        High Street, Wanstead, London E11 2RJ, United Kingdom &  */}
        UAB Driffle, Vilnius, Naugarduko g. 3-401, 03231, Lithuania.
      </CompanyInfo>
      <RecaptchaBadgeContainer>
        {/* This site is protected by reCAPTCHA and the Google{' '}
        <Link href="https://policies.google.com/privacy" passHref>
          <a>Privacy Policy</a>
        </Link>{' '}
        and{' '}
        <Link href="https://policies.google.com/terms" passHref>
          <a>Terms of Service</a>
        </Link>{' '}
        apply. */}
      </RecaptchaBadgeContainer>
    </FooterStripContainer>
  );
};

export default memo(FooterBottomStrip);
